<template>  
    <div id="payment-request-button" @click="handleApplePay"></div>
    <!-- <i></i> <strong>Try it for Free</strong> -->
</template>

<script>
import { loadStripe } from "@stripe/stripe-js"
import LandingServices from '@/services/LandingServices'
export default {
  methods: {    
    handleApplePay() {
      this.paymentRequest.show();
    },
    initMetrikaGoal() {
      setTimeout(() => {
        if (window.ym) {
          window.ym(process.env.VUE_APP_YANDEX_ID,'reachGoal','apple')
        } else {
          this.initMetrikaGoal()
        }
      }, 100);
    },
    async handlePayment(paymentMethod, pRequest) { 
      this.initMetrikaGoal()
      await window.fbq('track', 'Subscribe')
      // sending data to api
      LandingServices.checkoutApplePay({
        user_id: window.user_id,
        paymentMethod: paymentMethod,
        email: window.email
      }).then(() => {
        pRequest.complete('success')
        this.$router.push({ name: 'Payment succeed', params: { user_id: window.user_id }})
      }).catch(() => {
        pRequest.complete('fail')
        this.$router.push({ name: 'Payment failed'})
      })
    }
  },
  async mounted() {    
    // this.$emit('toggleApplePay', false)
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)
    // ApplePay
    this.paymentRequest = this.stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        amount: 0,
        label: "Total"
      }
    })
    this.paymentRequest.on("paymentmethod", event => {
      const paymentMethod = event.paymentMethod
      if (paymentMethod) {
        this.handlePayment(paymentMethod, event);
      }
    })
    this.paymentRequest.canMakePayment().then(result => {
      var elements = this.stripe.elements();
      var prButton = elements.create('paymentRequestButton', {
        paymentRequest: this.paymentRequest,
        style: {
          paymentRequestButton: {
            type: 'default',
            theme: 'dark',
            height: '55px'
          },
        },
      })
      if (!!result && result.applePay === true) {
        prButton.mount('#payment-request-button');
      } else {
        this.$emit('toggleApplePay', false)
      }
    })
  }
}
</script>